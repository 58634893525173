.container {
  background: linear-gradient(
    180deg,
    rgba(5, 155, 239, 0.64) 6.07%,
    rgb(255, 255, 255) 35.08%
  );
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
}
.homeBodyContainer {
  top: -20vh;
  position: relative;
  height: 50vh;
  width: auto;
}
.homeBodyContent {
  position: relative;
  display: flex;
  align-items: flex-start;
  top: 25vh;
  left: 13vw;
}
.homeBodyContent h1{
  align-items: left;
}
.illustratorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 10;
}

.illustratorContainer img {
  position: absolute;
  background-color: transparent;
  margin-right: 5%;
  width: 40vw;
  height: 50vh;
}
@media (max-width: 700px) {
  .homeBodyContainer {
    flex-direction: column;
  }
}

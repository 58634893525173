.container {
  background-color: transparent;
  position: relative;
  height: 35vh;
  width: auto;
}

.headerContainer {
  left: 0;
  right: 0;
  margin-top: 20;
  margin-left: 10;
  position: absolute;
  width: auto;
  z-index: 1;
}

.headerText {
  font-size: 30;
  font-weight: bold;
  color: rgb(227, 11, 11);
  text-align: center;
  width: 100vw;
}


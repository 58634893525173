.container {
  background: transparent;
}
.svgContainer {
  top: 130;
  position: "absolute";
}

.svgCurve {
  position: "absolute";
  height: 35vh;
}
